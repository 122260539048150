<template>
  <b-modal size="sm" :id="'modal-status' + contract.id" :title="'Contrato: ' + contract.code" ok-only hide-footer no-close-on-backdrop >
    <h4>Estado del contrato</h4>
    <ValidationObserver ref="uploadFilesForm" v-slot="{ invalid }">
      <b-form @submit.prevent="updateStatus">
        <b-row>
          <b-col>
            <ValidationProvider name="tipo de archivo" rules="required">
              <b-form-group label="Seleccione una opción" slot-scope="{ valid, errors }">
                  <b-form-select :state="errors[0] ? false : valid ? true : null" v-model="actualcontractstatus">
                    <option value="">Seleccione una opción</option>
                    <option v-for="status in showStatusContract" :key="status.id" :value="status.id" > {{ status.name }} </option>
                  </b-form-select>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="12" v-if="actualcontractstatus == 3">
            <ValidationProvider rules="required" name="Término del Contrato">
              <b-form-group label="Término del Contrato" slot-scope="{ valid, errors }">
                <b-form-input
                  class="form-control"
                  type="date"
                  v-model="selectedContractEndDate"
                  :state="errors[0] ? false : valid ? true : null"
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="12" v-if="actualcontractstatus == 3">
            <ValidationProvider name="nota" :rules="handlerStatus(actualcontractstatus)">
              <b-form-group label="Nota" slot-scope="{ valid, errors }">
                <b-form-textarea placeholder="Motivo de cancelación" v-model="textCancelation" :state="errors[0] ? false : valid ? true : null"></b-form-textarea>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="float-right">
              <b-button type="submit" variant="primary" class="float-right" :disabled="invalid || isUpdating" > Actualizar </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { showAlertMessage } from "@/helpers/helpers";

export default {
  props: {
    contract: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      tableColumns: [
        { key: 'dateInit', label: 'Fecha de Inicio' },
        { key: 'dateEnd', label: 'Fecha de Término' },
        { key: 'paymenttypename', label: 'Tipo de pago' },
        { key: 'returntypename', label: 'Tipo de retorno' },
      ],
      isUpdating: false,
      textCancelation: '',
      actualcontractstatus: '',
      notes: '',
      selectedContractEndDate: null
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('fivesClubCatalogs', ['paymentTypes', 'retornos']),
    ...mapState('fivesClubContracts', ['contractstatus','contracts','requestContracts']),
    showStatusContract() {
      let statuses = []
      if (this.contract.statusContract === 1 ){
        const isProposal = this.contractstatus.filter((stat) => stat.id == 2 || stat.id == 3 )
        statuses.push(...isProposal)
        return statuses
      }
      if (this.contract.statusContract == 2){
        const isSigned = this.contractstatus.find((stat) => stat.id == 3)
        statuses = [{...isSigned}]
        return statuses
      }
    },
  },
  methods: {
    ...mapActions('fivesClubContracts', ['updateStatusContract','fetchContracts', 'contractActiveServices']),
    ...mapMutations('fivesClubContracts', ['setContracts','setIsLoadingContracts']),

    handlerStatus(value) {
      let required = ''
      if(value){
        const { name } = this.contractstatus.find( status => status.id === value )
        return ( name === 'CANCELADO' || name === 'DESESTIMADO') ? 'required' : ''
      }
      return required
    },
    async updateStatus() {
      this.isUpdating = true
      const idContract = this.contract?.id
      let valid

      const actives = await this.contractActiveServices({house: this.contract.housingid, idContract})

      if (this.actualcontractstatus == 3 && actives?.length > 0) {
        valid = await this.autorizeCancelBookings(actives)
      }
      else valid = await this.confirmSaving()

      if (valid) {
        const payload = { idContract, status: this.actualcontractstatus, notes: this.textCancelation, idUser: this.user.idUser }
        if (this.actualcontractstatus == 3) payload.endDate = this.selectedContractEndDate
        const response = await this.updateStatusContract(payload)

        if (response){
          this.$root.$emit('bv::hide::modal', 'modal-status' + idContract )
          this.isUpdating = false
        } else this.isUpdating = false

        if( !this.contracts.length && this.requestContracts ){
          this.setContracts([])
          this.setIsLoadingContracts(true)
          const contracts = await this.fetchContracts(this.requestContracts)
          this.setContracts( contracts )
          this.setIsLoadingContracts(false)
          this.isUpdating = false
        }
      } else {
        this.isUpdating = false
        this.$root.$emit('bv::hide::modal', 'modal-status' + idContract )
        showAlertMessage('Cambio cancelado', 'InfoIcon', 'Se ha cancelado la actualización', 'danger', 4000, 'bottom-right' )
      }
    },
    async autorizeCancelBookings(actives){
      const component = this.createModalComponent(actives)

      const modal = await this.$bvModal.msgBoxConfirm([component],{
        title: 'Cancelando contrato: reservas activas',
        size: 'lg'
      })

      return modal
    },
    async confirmSaving() {
      const { isConfirmed } = await this.$swal.fire({
        title: "Cambiando estado del contrato",
        text: `¿Desea actualizar el contrato`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,

      })
      return isConfirmed
    },
    createModalComponent(data){
      const h = this.$createElement

      const datafields = [
        {key: 'reservanumber', label: 'Folio reserva'},
        {key: 'housingname', label: 'Vivienda'},
        {key: 'datein', label: 'Llegada'},
        {key: 'dateout', label: 'Salida'},
        {key: 'statusname', label: 'Estatus'}
      ]

      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['text-center mb-1'] }, [
          'Las siguientes reservas se cancelarán: ',
        ]),
        // h('p', { class: ['text-center'] }, [h('b-spinner')]),
        h('b-table', {
          props: {
            items: data,
            fields: datafields,
            small: true,
            responsive: true,
            // show-empty: true,
            // empty-text: "No matching records found"
          }
        }),
        h('strong', { class: ['text-center pt-1'] }, [
          '¿Está seguro de cancelar este contrato?',
        ]),
      ])
      return messageVNode
    }
  },
};
</script>
